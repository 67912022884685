import React, {useState, useEffect} from 'react';
import SideBar from "./Components/SideBar"
import NavBar from "./Components/NavBar"
import Footer from "./Components/Footer"
import routes from "./routes"
import {Switch,Route,Redirect} from "react-router-dom";
import Splash from "./Components/SplashScreen"
function App() {

  //const [slideIn,setSlideIn] = useState(false)
  const [liveScreenSize,setLiveScreenSize] = useState({width: 0, height: 0})
  const tvBreakPoint = 1820
  const monitorBreakPoint = 861
  const [mobileOpen,setMobileOpen] = useState(false)

  useEffect(() => {
  
      const updateDimensions = () => {
          if(window.innerWidth < 500) {
            setLiveScreenSize({ width: 450, height: 102 });
          } else {
            let update_width  = window.innerWidth-100;
            let update_height = Math.round(update_width/4.4);
            setLiveScreenSize({ width: update_width, height: update_height });
          }
        }
  
      updateDimensions();
      window.addEventListener("resize", updateDimensions);
  
      return function cleanup() {
          window.removeEventListener("resize", updateDimensions);
        };
    },[])

    let slideIn = liveScreenSize.width > tvBreakPoint || liveScreenSize.width < monitorBreakPoint ? true : false

    const handleMenuButton = () => {
      setMobileOpen(mobileOpen ? false : true)
    }

    const styles = {
      wrapper: {
        position: "relative",
        top: 0,
        height: "100%" 
      },
      view: {
        marginLeft: slideIn ? 0 : 260,
        paddingTop: 60,
        transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
      },
      container: {
        paddingRight: "15px",
        paddingLeft: "15px",
        marginRight: "auto",
        marginLeft: "auto",
        minHeight: "0px",
      }
    }

  return (
    <div style={styles.wrapper}>
      <Splash />
      <NavBar
        routes={routes}
        slideIn={slideIn}
        handleMenuButton={handleMenuButton}
      />
      <SideBar
        routes={routes}
        slideIn={slideIn}
        mobileOpen={mobileOpen}
        handleMenuButton={handleMenuButton}
      />
      <div style={styles.view}>
        <div style={styles.container}>
        <Switch>
          {routes.map(prop => {
            if (prop.layout === "/kat") {
              return (
                <Route
                  path={prop.layout + prop.path}
                  render={props => (
                    <prop.component
                      liveScreenSize={liveScreenSize}
                      //props here
                      {...props}
                    />
                  )}
                  key={prop}
                />
              );
            }
            return null;
          })}
          <Route path={"/kat/curriculumvitae"} />
          <Redirect from="/kat" to="/kat/curriculumvitae" />
        </Switch>
        </div>
        <Footer slideIn={slideIn} />
      </div>
    </div>

  );
}

export default App;
