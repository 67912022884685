import React from 'react';

function GridContainer(props) {
  const {children} = props

  const styles = {
      container: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        padding: 5
      },
  }

  return (
    <div style={styles.container}>
        {children}
    </div>
  );
}

export default GridContainer;
