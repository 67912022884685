import React, {useState, useEffect} from 'react';

const GridItem = (props) => {
  const {
    children = "Child Element Goes Here",
    tv = 3,
    largeMonitor = 3,
    monitor = 3,
    tablet = 6,
    mobile = 12
  } = props

  const [liveScreenSize,setLiveScreenSize] = useState({width: 800, height: 182})
  const tvBreakPoint = 1820
  const largeMonitorBreakPoint = 1280
  const monitorBreakPoint = 861 
  const tabletBreakPoint = 700
  //const mobileBreakPoint = 500

  const tvCurrentWidth = Number(tv > 12 ? 12 : tv * 8.33).toFixed(2) + '%'
  const largeMonitorCurrentWidth = Number(largeMonitor > 12 ? 12 : largeMonitor * 8.33).toFixed(2) + '%'
  const monitorCurrentWidth = Number(monitor > 12 ? 12 : monitor * 8.33).toFixed(2) + '%'
  const tabletCurrentWidth = Number(tablet > 12 ? 12 : tablet * 8.33).toFixed(2) + '%'
  const mobileCurrentWidth = Number(mobile > 12 ? 12 : mobile * 8.33).toFixed(2) + '%'

  useEffect(() => {

    const updateDimensions = () => {
        if(window.innerWidth < 500) {
          setLiveScreenSize({ width: 450, height: 102 });
        } else {
          let update_width  = window.innerWidth-100;
          let update_height = Math.round(update_width/4.4);
          setLiveScreenSize({ width: update_width, height: update_height });
        }
      }

    updateDimensions();

    window.addEventListener("resize", updateDimensions);

    return function cleanup() {
        window.removeEventListener("resize", updateDimensions);
      };
  },[])

  let itemWidthBasedOnScreenSize = liveScreenSize.width > tvBreakPoint
  ? tvCurrentWidth
  : liveScreenSize.width > largeMonitorBreakPoint
  ? largeMonitorCurrentWidth
  : liveScreenSize.width > monitorBreakPoint
  ? monitorCurrentWidth
  : liveScreenSize.width > tabletBreakPoint
  ? tabletCurrentWidth
  : mobileCurrentWidth

  const styles = {
    item: {
      background: "transparent",
      width: `calc(${itemWidthBasedOnScreenSize})`}
    }

  return(
    <div style={styles.item}>
      {children}
    </div>
  )
}

export default GridItem;
