import React from "react"
import Menu from "@material-ui/icons/Menu"
import Hide from "./Hide"

const NavBar = (props) => {
  const {
      client = "About Me",
      routes = {},
      slideIn = false,
      handleMenuButton
    } = props

  const styles = {
    appBar: {
      marginLeft: slideIn ? 0 : 260,
      background: "transparent",
      boxShadow: "none",
      borderBottom: 0,
      marginBottom: 0,
      position: "absolute",
      width: `calc(100% - ${slideIn ? 0 : 260}px)`,
      paddingTop: "10px",
      color: "#555555",
      zIndex: 5,
      border: 0,
      fontSize: 16,
      borderRadius: "5px",
      padding: "10px 0",
      transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
      minHeight: "50px",
      display: "block"
    },
    toolbar: {
      position: 'relative',
      display: 'flex', 
      alignItems: 'center',
      paddingRight: "15px",
      paddingLeft: "15px",
      marginRight: "auto",
      marginLeft: "auto",
      minHeight: "0px",
    },
    flex: {
      flex: 1,
      fontSize: 16,
    },
    menubutton: {
      textAlign: 'center',
      flex: '0 0 auto',
      fontSize: "calc(16px + 0.5vw)",
      padding: 12,
      borderRadius: '50%',
      overflow: 'visible',
      background: 'transparent',
      border: "none",
      outline: "none",
      color: "#555555"
    }
  }

  const makeBrand = () => {
    let name;
    routes.map((tag) => {
      if (window.location.href.indexOf(tag.layout + tag.path) !== -1) {
        name = tag.name;
      }
      return(null)
      });
      return (
        <span>
          <b>
            {client} - {name || 'Jekaterina Ivanova'}
          </b>
        </span>);
  }

  return (
    <div style={styles.appBar}>
      <div style={styles.toolbar}>
        <div style={styles.flex}>{makeBrand()}</div>
        
        <Hide hide={['lm','dm']} >
          <button onClick={handleMenuButton} style={styles.menubutton}>
            <Menu />
          </button>
        </Hide>

      </div>
    </div>
  )
}

export default NavBar
