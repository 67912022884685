import Check from "@material-ui/icons/Check"
import Test from "./Views/Test"
import Sandbox from "./Views/Sandbox"

const dashboardRoutes = [
  {
    path: "/curriculumvitae",
    name: "Curriculum Vitae",
    icon: Check,
    component: Sandbox,
    layout: "/kat",
    hideFor: ["DUNSTK2","CATHK"]
  }
];

export default dashboardRoutes;
