import React, {useEffect,useState} from "react"
import moment from "moment"

const Footer = (props) => {
  const {slideIn} = props
  const [time,setTime] = useState(<React.Fragment>{moment().format('MMMM Do YYYY')} - {moment().format('h:mm:ss a')}</React.Fragment>)
  
  const styles = {
    footer: {
        position: "fixed",
        left: 0,
        bottom: 0,
        width: "100%",
        backgroundColor: "transparent",
        color: "#555555",
        fontWeight: "bold",
        fontSize: 12
    },
    leftText: {
      float: "left",
      textAlign: "left",
      paddingLeft: slideIn ? 10 : 270,
      transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
    },
    rightText: {
      float: "right",
      textAlign: "right",
      paddingRight: 10,
      display: slideIn ? "none" : null,
    }
  }

  useEffect(() => {

    let interval

    const tick = () => {
      setTime(<React.Fragment>{moment().format('MMMM Do YYYY')} - {moment().format('h:mm:ss a')}</React.Fragment>);
    }

    tick();
    
    interval = setInterval(tick, 1000);
    return () => clearInterval(interval);

  }, []);

  return (
    <footer style={styles.footer}>
      <span style={styles.leftText}>
        {time}
      </span>
      <span style={styles.rightText}>
        &copy; {new Date().getFullYear()} Jekaterina Ivanova
      </span>
    </footer>
  );
}

export default Footer;
