import React from 'react'

const CardStatic = (props) => {
    const {children} = props

    const styles = {
        root: {
            padding: 5
        },
        paper: {
            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
            background: "white",
            minHeight: 200,
            borderRadius: 5,
            padding: 5
        }
        }

    return (
        <div style={styles.root}>
            <div style={styles.paper}>
                {children}
            </div>
        </div>
    )
}

export default CardStatic