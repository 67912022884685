import React from 'react'
import GridContainer from "../Components/GridContainer"
import GridItem from "../Components/GridItem"
import CardStatic from "../Components/CardStatic"
import CardHover from "../Components/CardHover"

const Test = ({...props}) => {

    return (
        <GridContainer>

            <GridItem tv={8} largeMonitor={8} monitor={8} tablet={12} mobile={12} >
            <CardStatic>
                    <div style={{paddingLeft: 10, fontSize: 18, fontWeight: 600}}>About Me</div>
                    <p style={{paddingLeft: 10, paddingRight: 10}}>
                    An able and dedicated person with a good work ethic and professional attitude
                    , keen to work well and always to the best of my abilities
                    . Diverse, creative and always thinking “outside the box”
                    . Enthusiastic member of any team and always looking to make own contribution to the overall effort
                    . Someone who enjoys encouraging and motivating those around her and seeking out improvement where possible.
                    </p>
                    <p style={{paddingLeft: 10, paddingRight: 10}}>
                    Able to cope well in challenging situations and under pressure
                    . Meets targets and deadlines by good organisation and time management skills.
                    Currently studying a Medical Science degree with a predicted 2:1. Aside from medical knowledge
                    , a keen interest technology. Computer literate with understanding of PowerPoint
                    , Excel, R, HTML, CSS and Phyton.
                    </p>

                </CardStatic>
            </GridItem>

            <GridItem tv={4} largeMonitor={4} monitor={4} tablet={12} mobile={12} >
                <CardHover>
                <div style={{paddingLeft: 10, fontSize: 18, fontWeight: 600}}>Contact</div>
                <ul>
                    <li>
                        Telephone: 07754 521 618
                    </li>
                    <li>
                        Email: katjaandiva@gmail.com
                    </li>
                    <li>
                        Website: www.jekaterinaivanova.co.uk
                    </li>
                    <li>
                        LinkdIn: Click Here
                    </li>
                </ul>
                </CardHover>
            </GridItem>

            <GridItem tv={12} largeMonitor={12} monitor={12} tablet={12} mobile={12} >
                <CardStatic>
                <div style={{paddingLeft: 10, fontSize: 18, fontWeight: 600}}>Key Strengths</div>
                    <p style={{paddingLeft: 10, paddingRight: 10}}>
                    <b>Decision Making</b><br />
                    Creative thinker. Willing to listen to input from others. Decisive when quick decisions are needed
                    , developed during managing position and on call duties. 
                    </p>

                    <p style={{paddingLeft: 10, paddingRight: 10}}>
                    <b>Communication</b><br />
                    Excellent communicator, both verbally and written
                    . Understands audience and able to articulate myself professionally and competently.
                    </p>

                    <p style={{paddingLeft: 10, paddingRight: 10}}>
                    <b>Team Work</b><br />
                    I enjoy sharing and debating ideas and ways forward, and compromise towards a common goal
                    . Received positive written feedback after teamworking on a research project for drug development in breast cancer.
                    </p>

                    <p style={{paddingLeft: 10, paddingRight: 10}}>
                    <b>Interpersonal Skills</b><br />
                    A friendly and competent networker. Personable and able to quickly establish rapport with people
                    . Important as part of a previous public role as a Deputy Mayoress for Corby
                    . Involved in numerous volunteering positions with a return of excellent references.
                    </p>
                </CardStatic>
            </GridItem>

        </GridContainer>
    )
}

export default Test