import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Router,Route,Switch,Redirect} from "react-router-dom";
import {createBrowserHistory} from "history";

const hist = createBrowserHistory();

const render = () => {

  ReactDOM.render(
    <Router history={hist}>
      <Switch>
      <Route path="/kat" component={App} />
        <Redirect from="/" to="/kat/" />
      </Switch>
    </Router>,
    document.getElementById("root")
  );}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
