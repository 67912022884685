import React from 'react'
import {NavLink} from "react-router-dom"
import image from "../katlesta.jpg"

const SideBar = (props) => {
  const {
    slideIn = false,
    mobileOpen = false,
    handleMenuButton = false,
    routes
  } = props

const styles = {
  rootLeft: {
    height: "100%",
    width: 260,
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: slideIn ? -260 : 0,
    backgroundImage: "url(" + image + ")" ,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    color: "white",
    transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
  },
  sidebarOpacity: {
    background: "rgba(24,24,24,0.9)",
    wdith: "100%",
    height: "100%"
  },
  rootRight: {
    height: "100%",
    width: 260,
    position: "fixed",
    top: 0,
    right: mobileOpen ? 0 : -260,
    backgroundImage: "url(" + image + ")" ,
    backgroundSize: "cover",
    backgroundPosition: "center left",
    color: "white",
    transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
    zIndex: 11
  },
  logo: {
    position: "relative",
    padding: "15px 15px",
    fontSize: "18px",
    color: "white",
    zIndex: 2,
  },
  img: {
    fontSize: "24px",
    verticalAlign: "middle",
    },
  button: {
    color: "white",
    background: "rgba(255,255,255,0.03)",
    display: "block",
    padding: 10,
    position: "relative",
    textDecoration: "none",
    transition: "all 300ms linear",
    borderRadius: 3,
    width: "90%"
  },
  itemIcon: {
    width: "24px",
    height: "30px",
    fontSize: "24px",
    lineHeight: "30px",
    float: "left",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    color: "white"
  },
  itemText: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 300,
    margin: "0",
    lineHeight: "30px",
    fontSize: "14px",
    color: "white"
    },
    activeButton: {
      background: "rgba(330,90,90,0.7)",
      display: "block",
      padding: 10,
      position: "relative",
      textDecoration: "none",
      transition: "all 300ms linear",
      borderRadius: 3,
      width: "90%",
    },
  sidebarWrapper: {
    overflow: "auto",
    background: "transparent",
    paddingTop: 0
  },
  mobileClickOutsideClose: {
    zIndex: 10,
    position: "fixed",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.6)"
  },
  buttonPadding: {
    padding: 5
  },
  listStyle: {
    display: "block",
  }
}

const links = (
  <div style={styles.sidebarWrapper}>
      <ul style={{position: "realtive", paddingLeft: 0, background: "transparent"}}>
        {routes.map((tag) => {
          if (!tag.hideFor.includes("test")) {
          return (
            <div style={styles.buttonPadding}>
            <NavLink
              to={tag.layout + tag.path}
              style={styles.button}
              activeStyle={styles.activeButton}
              key={tag.layout + tag.path}
            >
            <li style={styles.listStyle}> 
                <tag.icon style={styles.itemIcon}/>
                  <span style={styles.itemText} >{tag.name}</span>
                  </li>
            </NavLink>
            </div>
          );
        } return (null)})}
      </ul>
    </div>)

  let branding = (
    <div style={{display: "flex"}}>
<div style={{paddingTop: 15, paddingLeft: 10}}>
  <div style={{borderRadius: "50%", overflow: "hidden", width: 60, height: 60}}>
<img src={image} alt="Avatar" style={{marginTop: -5, width: 70, height: "auto"}} />
</div>
</div>

    
    <div style={styles.logo}>
    <span style={{fontSize: 16}}>{'Jekaterina'}</span><br/>
    <b style={{fontSize: 40}}>{'Ivanova'}</b>
  </div>
      </div>
  )

  return (
    <React.Fragment>
      {mobileOpen && <div onClick={handleMenuButton} style={styles.mobileClickOutsideClose} />}

      <div style={styles.rootLeft}>
        <div style={styles.sidebarOpacity}>
          {branding}
          {links}
        </div>
      </div>

      <div style={styles.rootRight}>
        <div style={styles.sidebarOpacity}>
          {branding}
          {links}
        </div>
      </div>
    </React.Fragment>
  )
}

export default SideBar;
