import React, {useState} from 'react'
import image from "../katlesta.jpg"

const SplashScreen = () => {

const [hover,setHover] = useState(false)
const [click,setClick] = useState(false)
const handleHover = () => {
    setHover(hover ? false : true)
}
const handleClick = () => {
    setClick(click ? false : true)
}

    const styles = {
        root: {
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 10,
            background: "thistle",
            width: "100%",
            height: "100%",
            textAlign: "center",
            backgroundImage: "url(" + image + ")" ,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            opacity: click ? 0 : 1,
            transition: "all 0.66s cubic-bezier(0.685, 0.0473, 0.346, 1)",
            pointerEvents: click ? "none" : null
        },
        opacity: {
            position: "absolute",
            background: "rgba(330,90,90,0.7)",
            width: "100%",
            height: "100%",
            opacity: click ? 0 : 1,
            transition: "all 0.66s cubic-bezier(0.685, 0.0473, 0.346, 1)",
            pointerEvents: click ? "none" : null
        },
        welcome: {
            fontSize: 38,
            marginTop: click ? 1400 : 400,
            width: 200,
            padding: 10,
            textAlign: 'center',
            background: "black",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 3,
            cursor:"pointer",
            opacity: 0.8,
            color: hover ? "white" : "rgba(330,90,90,0.7)",
            transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
        }
    }

    return(
        <div style={styles.root}>
            <div style={styles.opacity}>
            <div onClick={handleClick} onMouseEnter={handleHover} onMouseLeave={handleHover} style={styles.welcome}>
                    Welcome    
            </div>
            </div>
        </div>
    )
}

export default SplashScreen