import React, {useState,useEffect} from 'react'

const Hide = (props) => {
    const {children,hide = "all"} = props
    const [liveScreenSize,setLiveScreenSize] = useState({width: 800, height: 182})
    const tvBreakPoint = 1821
    const largeMonitorBreakPoint = 1280
    const monitorBreakPoint = 861 
    const tabletBreakPoint = 700
    //const mobileBreakPoint = 500

    useEffect(() => {

        const updateDimensions = () => {
            if(window.innerWidth < 500) {
              setLiveScreenSize({ width: 450, height: 102 });
            } else {
              let update_width  = window.innerWidth-100;
              let update_height = Math.round(update_width/4.4);
              setLiveScreenSize({ width: update_width, height: update_height });
            }
          }
    
        updateDimensions();
    
        window.addEventListener("resize", updateDimensions);
    
        return function cleanup() {
            window.removeEventListener("resize", updateDimensions);
          };
      },[])

      const currentScreen = liveScreenSize.width >= tvBreakPoint
        ? "tv"
        : liveScreenSize.width >= largeMonitorBreakPoint
        ? "lm"
        : liveScreenSize.width >= monitorBreakPoint
        ? "dm"
        : liveScreenSize.width >= tabletBreakPoint
        ? "td"
        : "md"

        let screenMatch = hide === "all" || hide.includes(currentScreen)  

    return (
        screenMatch ? null : 
        <React.Fragment>
            {children} 
        </React.Fragment>
    )

}

export default Hide