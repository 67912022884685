import React, {useState} from 'react'

const CardHover = (props) => {
    const {children} = props
    const [mouseOver,setMouseOver] = useState(false)

    const handleMouseOver = () => {
        setMouseOver(mouseOver ? false : true)
    }

    const styles = {
        root: {
            padding: 5
        },
        paper: {
            boxShadow: mouseOver ? '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)' : '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
            transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
            background: "white",
            minHeight: 200,
            borderRadius: 5,
            padding: 5
        }
        }

    return (
        <div style={styles.root}>
            <div onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOver} style={styles.paper}>
                {children}
            </div>
        </div>
    )
}

export default CardHover